@keyframes float {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(10px);
    }

    100% {
        transform: translateY(0);
    }
}

.float {
    animation: 3s float;
    animation-iteration-count: infinite;
    animation-timing-function: ease;
}

@keyframes indicate {
    0% {
        transform: translateX(0);
    }

    50% {
        transform: translateX(2px);
    }

    100% {
        transform: translateX(0);
    }
}

.float-left {
    animation: 0.4s indicate;
    animation-iteration-count: infinite;
    animation-timing-function: ease;
}

.slide-up {
    animation: 3s slide-up;
}

.slide-left {
    animation: 0.5s slide-left;
}

.failed {
    animation: 0.1s failed;
}

.glow {
    animation: 0.3s glow;
    animation-iteration-count: infinite;
}

.reminder {
    animation: 4s reminder;
    animation-iteration-count: infinite;
}

.fade-in {
    animation: 0.4s fade-in forwards;
}

@keyframes fade-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes slide-up {
    from {
        margin-top: 1em;
        opacity: 0;
    }

    to {
        margin-top: 0;
        opacity: 1;
    }
}

@keyframes slide-left {
    from {
        margin-left: 5em;
        opacity: 0;
    }

    to {
        margin-left: 0;
        opacity: 1;
    }
}

@keyframes glow {
    0% {
        outline: solid 3px rgba(82, 255, 145, 0.5);
        -webkit-box-shadow: 0px 5px 10px 0px rgba(82, 255, 145, 0.5);
        -moz-box-shadow: 0px 5px 10px 0px rgba(82, 255, 145, 0.5);
        box-shadow: 0px 5px 10px 0px rgba(82, 255, 145, 0.5);
    }

    33% {
        outline: solid 3px rgba(82, 255, 145, 1);
        -webkit-box-shadow: 0px 5px 15px 0px rgba(82, 255, 145, 1);
        -moz-box-shadow: 0px 5px 15px 0px rgba(82, 255, 145, 1);
        box-shadow: 0px 5px 15px 0px rgba(82, 255, 145, 1);
    }

    100% {
        outline: solid 3px rgba(82, 255, 145, 0.5);
        -webkit-box-shadow: 0px 5px 10px 0px rgba(82, 255, 145, 0.5);
        -moz-box-shadow: 0px 5px 10px 0px rgba(82, 255, 145, 0.5);
        box-shadow: 0px 5px 10px 0px rgba(82, 255, 145, 0.5);
    }
}

@keyframes failed {
    0% {
        transform: translate(0, 0) rotate(0);
    }

    25% {
        transform: translate(-5px, 0) rotate(2deg);
    }

    50% {
        transform: translate(5px, 0) rotate(0);
    }

    75% {
        transform: translate(-5px, 0) rotate(-2deg);
    }

    100% {
        transform: translate(0, 0) rotate(0);
    }
}

@keyframes reminder {
    0% {
        opacity: 0;
    }

    70% {
        opacity: 0;
    }

    75% {
        opacity: 1;
        margin-top: 30%;
        margin-left: -3%;
    }

    85% {
        margin-left: 5%;
    }

    100% {
        margin-top: 0;
        margin-left: 0;
    }
}

@keyframes scrolling-right {
    from {
        background-position: 0 0;
    }

    to {
        background-position: 4720px 0;
    }
}

@keyframes scrolling-left {
    from {
        background-position: 720px 0px;
    }

    to {
        background-position: -4000px 0px;
    }
}

.bg-smoke {
    pointer-events: none;
    position: absolute;
    bottom: 100px;
    width: 100%;
    height: 1180px;
    opacity: 0.8;
    background: url("assets/misc/smoke.png") repeat;
    animation: scrolling-right 100s linear infinite;
}

.fg-smoke {
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 1180px;
    bottom: -400px;
    background: url("assets/misc/smoke.png");
    animation: scrolling-left 70s linear infinite;
}

@keyframes shine {
    0% {
        color: white;
    }

    50% {
        text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #e60073, 0 0 40px #e60073, 0 0 50px #e60073, 0 0 60px #e60073, 0 0 70px #e60073;
        color: #e1ff00;
    }

    100% {
        color: #e1ff00;
    }
}

@keyframes outer-shine {
    0% {
        transform: scale(0) rotate(-5deg);
        -webkit-box-shadow: 0px 5px 10px 0px white;
        -moz-box-shadow: 0px 5px 10px 0px white;
        box-shadow: 0px 5px 10px 0px white;
    }

    50% {
        transform: scale(1.2) rotate(5deg);
        -webkit-box-shadow: 0px 5px 10px 0px #e1ff00;
        -moz-box-shadow: 0px 5px 10px 0px #e1ff00;
        box-shadow: 0px 5px 10px 0px #e1ff00;
    }

    100% {
        transform: scale(1);
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
    }
}

@keyframes unlock {
    0% {
        filter: brightness(0);
    }

    2% {
        filter: brightness(0) invert(1);
    }

    4% {
        filter: brightness(0);
    }

    6% {
        filter: brightness(0) invert(1);
    }

    8% {
        filter: brightness(0);
    }

    10% {
        filter: brightness(0) invert(1);
    }

    12% {
        filter: brightness(0);
    }

    16% {
        filter: brightness(0) invert(1);
    }

    22% {
        filter: brightness(0);
    }

    30% {
        filter: brightness(0) invert(1);
    }

    40% {
        filter: brightness(0);
    }

    50% {
        filter: brightness(0) invert(1);
    }

    67% {
        filter: brightness(0);
    }

    70% {
        filter: brightness(0);
    }

    80% {
        filter: brightness(1);
    }

    100% {
        filter: brightness(1);
    }
}
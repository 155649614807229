@font-face {
    font-family: Atalon;
    src: url(assets/fonts/Atalon.otf);
}

@font-face {
    font-family: PhantaDu;
    src: url(assets/fonts/Phanta-Du.ttf);
}

body {
    margin: 0;
    font-family: PhantaDu;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #2F2F2F;
    width: 100%;
    height: 100vh;
    color: #FFFFFF;
    overflow: hidden;
}

body * {
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

input {
    border: 0;
    outline: 0;
}

input:focus {
    outline: none !important;
}

#root {
    overflow: hidden;
}

.button {
    line-height: 2em;
    background-image: url(assets/misc/button.png);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    font-size: 40px;
    max-width: 350px;
    text-align: center;
    margin: auto;
    cursor: pointer;
    transition: opacity 0.2s;
    user-select: none;
}

.button:hover {
    opacity: 0.9;
}

.clickable {
    cursor: pointer;
    user-select: none;
}

.selected {
    border: solid 2px white;
    border-radius: 5px;
}

.placeholder {
    opacity: 0.5;
}

.no-select {
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.unlock {
    animation: unlock 8s forwards;
}